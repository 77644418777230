$colorWhite: #ffffff;
$colorLightGrey: #c7cbd0;
$colorMediumGrey: #555961;
$colorDarkGrey: #23252d;
$colorBlack: #000000;
$colorOtherGrey: #d8d8d8;
$colorBorderGrey: #979797;

//Primary colors
$stone-grey: #23252d;
$orange: #ffcc66;
$white: #ffffff;
$blue: #4183c4;

//Secondary colors
$black: #000000;
$dove-grey: #555961;
$steel-grey: #c7cbd0;
$matterhorn-grey: #505050;
$dropdown: #282a31;
$error-red: #c70039;

//Transitions
$slow-transition: 1.2s;
$medium-transition: 0.8s;
$fast-transition: 0.42s;
$fastest-transition: 0.25s;

//Font sizes
$headline: 2.5em;

//hover
$hover: #ffab00;
$hover-back: #979797;
