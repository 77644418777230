@import './../../styles/colors';
@import './../../styles/responsiveness';

.HowDoesItWork {
  min-height: 100vh;
  padding: 0 10% 0 10%;
  position: relative;

  .ContactInfo {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 0;
  }

  .meta-text-content {
    width: 35%;
    margin-bottom: 5%;
    font-size: 1.2em;
    padding-top: 1%;

    
  }

  .heading h1 {
    font-size: 2.5em;
  }

  .progress-line-container {
    padding-left: 1%;
  }

  .progress-line-titles {
    width: 100%;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2.5%;
  }

  .progress-line-title {
    width: 15%; // offset 100-progressLineWidth
  }

  .progress-line-descriptions {
    margin-top: 2.5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.2em;
  }

  .progress-line-description {
    width: 15%; // offset 100-progressLineWidth

    color: grey;

    a {
      b {
        color: grey;
      }
    }

    &.active {
      color: black;

      a {
        color: black;

        b {
          color: black;
        }
      }
    }
  }

  .how-does-it-work-wrapper {
    width: 100%;
    height: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
  }
}

@media (max-width: $small-desktop) {
  .HowDoesItWork {
    padding-bottom: 15%;

    .meta-text-content {
      width: 100%;
      margin-bottom: 5%;
      font-size: 1.2em;
      padding-top: 1%;
      padding-bottom: 5%;
    }

    .heading h1 {
      font-size: 2.5em;
    }

    .progress-line-container {
      width: 60vh;
      .progress-line {
        transform-origin: left;
        transform: rotate(90deg); //translateY(-80vw) translateX(30vh);
      }
    }

    .progress-line-title-and-description {
      display: flex;
      flex-direction: column;
      padding-left: 10%;
      height: 20vh;

      .progress-line-title {
        font-size: 1.5em;
        width: 100%; // offset 100-progressLineWidth
      }

      .progress-line-description {
        margin-top: 2.5%;
        width: 90%;
        font-size: 1.2em;

        color: grey;

        a {
          b {
            color: grey;
          }
        }

        &.active {
          color: black;

          a {
            color: black;

            b {
              color: black;
            }
          }
        }
      }
    }

    .how-does-it-work-wrapper {
      height: 100vh;
      .mobile-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80vh;
        margin-top: -15px;
      }
    }
  }
}

@media (max-width: $large-phone) {
  .HowDoesItWork {
    padding-bottom: 15%;

    .meta-text-content {
      width: 100%;
      margin-bottom: 5%;
      font-size: 1.2em;
      padding-top: 1%;
      padding-bottom: 5%;
    }

    .heading h1 {
      font-size: 2.5em;
    }

    .progress-line-container {
      width: 80vh;
      .progress-line {
        transform-origin: left;
        transform: rotate(90deg); //translateY(-80vw) translateX(30vh);
      }
    }

    .progress-line-title-and-description {
      display: flex;
      flex-direction: column;
      padding-left: 10%;
      height: 20vh;

      .progress-line-title {
        font-size: 1.5em;
        width: 100%; // offset 100-progressLineWidth
      }

      .progress-line-description {
        margin-top: 2.5%;
        width: 90%;
        font-size: 1.2em;
      }
    }

    .how-does-it-work-wrapper {
      height: 120vh;
      .mobile-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        margin-top: -15px;
      }
    }
  }
}

@media (max-width: $medium-large-phone) {
  .HowDoesItWork {
    padding-bottom: 15%;

    .meta-text-content {
      width: 100%;
      margin-bottom: 5%;
      font-size: 1.2em;
      padding-top: 1%;
      padding-bottom: 5%;
    }

    .heading h1 {
      font-size: 2.5em;
    }

    .progress-line-container {
      width: 100vh;
      .progress-line {
        transform-origin: left;
        transform: rotate(90deg); //translateY(-80vw) translateX(30vh);
      }
    }

    .progress-line-title-and-description {
      display: flex;
      flex-direction: column;
      padding-left: 10%;
      height: 20vh;

      .progress-line-title {
        font-size: 1.5em;
        width: 100%; // offset 100-progressLineWidth
      }

      .progress-line-description {
        margin-top: 2.5%;
        width: 90%;
        font-size: 1.2em;
      }
    }

    .how-does-it-work-wrapper {
      height: 140vh;
      .mobile-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 120vh;
        margin-top: -15px;
      }
    }
  }
}

@media (max-width: $medium-phone) {
  .HowDoesItWork {
    padding-bottom: 25%;
    min-height: 160vh;

    .meta-text-content {
      width: 100%;
      margin-bottom: 5%;
      font-size: 1.2em;
      padding-top: 1%;
      padding-bottom: 5%;
    }

    .heading h1 {
      font-size: 2.5em;
    }

    .progress-line-container {
      width: 120vh;
      .progress-line {
        transform-origin: left;
        transform: rotate(90deg); //translateY(-80vw) translateX(30vh);
      }
    }

    .progress-line-title-and-description {
      display: flex;
      flex-direction: column;
      padding-left: 10%;
      height: 20vh;

      .progress-line-title {
        font-size: 1.5em;
        width: 100%; // offset 100-progressLineWidth
      }

      .progress-line-description {
        margin-top: 2.5%;
        width: 90%;
        font-size: 1.2em;
      }
    }

    .how-does-it-work-wrapper {
      height: 160vh;
      .mobile-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 140vh;
        margin-top: -15px;
      }
    }
  }
}

@media (max-height: 700px) {
  .HowDoesItWork {
    padding-bottom: 25%;
    min-height: 180vh;

    .meta-text-content {
      width: 100%;
      margin-bottom: 5%;
      font-size: 1.2em;
      padding-top: 1%;
      padding-bottom: 5%;
    }

    .heading h1 {
      font-size: 2.5em;
    }

    .progress-line-container {
      width: 140vh;
      .progress-line {
        transform-origin: left;
        transform: rotate(90deg); //translateY(-80vw) translateX(30vh);
      }
    }

    .progress-line-title-and-description {
      display: flex;
      flex-direction: column;
      padding-left: 10%;
      height: 20vh;

      .progress-line-title {
        font-size: 1.5em;
        width: 100%; // offset 100-progressLineWidth
      }

      .progress-line-description {
        margin-top: 2.5%;
        width: 90%;
        font-size: 1.2em;
      }
    }

    .how-does-it-work-wrapper {
      height: 190vh;
      .mobile-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 160vh;
        margin-top: -15px;
      }
    }
  }
}
