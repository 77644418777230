@import '../../styles/colors';
@import '../../styles/responsiveness';

.Synchronizer {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 8% 10% 5% 10%;
  background-color: $stone-grey;
  min-height: 100vh;

  a {
    color: $orange;

    transition: $fast-transition;
    &:hover {
      color: darken($color: $orange, $amount: 20%);
      transition: $medium-transition;
    }
  }

  .react-datepicker__day--disabled {
    color: $matterhorn-grey !important;
  }

  .error-message {
    color: $error-red;
    font-size: 1.4em;

    i {
      margin-left: 10px;
    }
  }

  .next-button-wrapper {
    cursor: pointer;

    #arrow-path {
      fill: $orange;
    }
  }

  .loaderWrapper {
    position: absolute;
    right: 50%;
    left: 50%;
    bottom: 50%;
    top: 50%;
    transform: translateY(-25px) translateX(-50px);

    @media (prefers-reduced-motion: no-preference) {
      img {
        animation: App-logo-spin infinite 2.5s linear;
      }
    }
    img {
      width: 100px;
      pointer-events: none;
    }
  }

  .headline {
    h1 {
      font-size: $headline;
    }
  }

  h1 {
    font-size: 2em;
    color: $white;
    font-weight: bolder;
  }

  .meta-description {
    padding-top: 3%;
    width: 45%;
    font-size: 1.5em;
    color: $white;
  }

  .ProgressLine {
    margin: 10% 0 10% 0;
  }

  .SynchronizerPage {
    position: relative;

    .description {
      padding-top: 3%;
      width: 45%;
      font-size: 1.5em;
      color: $white;
      font-weight: bold;
    }

    .react-datepicker__input-container {
      z-index: 1000;
    }

    .syncin-calendar-wrapper {
      height: fit-content;
      width: 100%;

      .dropdown-arrow {
        pointer-events: none;
        z-index: 2000;

        img {
          z-index: 2000;
          pointer-events: none;
          right: 0;
          top: 50px;
          position: absolute;
          height: 20px;
        }
      }

      .divider {
        height: 2px;
        width: 100%;
        background-color: $matterhorn-grey;
      }

      .syncin-calendar {
        cursor: pointer;
        background-color: $stone-grey;
        text-decoration: none;
        border: none;
        width: 100%;
        height: 30px;
        font-size: 1.25em;
        padding-left: 5px;
        outline-width: 0;
        text-decoration: none;
        color: $orange;
        overflow: hidden;
        margin: 0;
      }
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__input-container {
      width: inherit;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker {
      border: none;
      box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 3px;
    }

    .react-datepicker__day--keyboard-selected {
      border-radius: 100%;
      background-color: $orange;
    }

    .react-datepicker__day--selected {
      background-color: $orange;
      border-radius: 100%;
    }

    .react-datepicker__month-container,
    .react-datepicker__header {
      background-color: $stone-grey;
      border-bottom: none;
      border: none;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: $white;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: $white;
    }

    .question-container {
      width: 40%;
      position: relative;
      padding-top: 50px;

      .currency-wrapper {
        display: flex;

        .TextInput {
          flex: 33;
        }
      }

      .currency-input-container {
        .currency-input {
          background-color: $stone-grey;
          text-decoration: none;
          color: $orange;
          border: none;
          width: 20vw;
          height: 30px;
          font-size: 1.25em;
          padding-left: 5px;
          outline-width: 0;
          text-decoration: none;
          overflow: hidden;
        }

        .divider {
          height: 2px;
          width: 100%;
          background-color: $matterhorn-grey;
        }
      }

      .duration-wrapper {
        display: flex;

        .TextInput {
          flex: 33;
        }
      }

      .question-wrapper {
        margin-bottom: 50px;
      }

      .terms-and-conditions {
        display: flex;
        flex-direction: row;

        .CheckBox {
          transform: translateX(-10px);
        }

        p {
          margin-top: 3px;
          margin-left: 5px;
          font-size: 16px;
          color: $white;
        }

        a {
          text-decoration: none;
          color: $orange;

          &:hover {
            color: $hover;
            transition: $fast-transition ease-in-out;
          }
        }
      }

      .button-container {
        padding-top: 10%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: $medium-large-phone) {
  .Synchronizer {
    padding: 30% 10% 5% 10%;
    padding-top: 110px;

    h1 {
      font-size: 2em;
      color: $white;
      font-weight: bolder;
    }

    .ProgressLine {
      margin: 10% 0 10% 0;
    }

    .SynchronizerPage {
      position: relative;

      .description {
        padding-top: 3%;
        width: 65%;
        font-size: 1.5em;
        color: $white;
        font-weight: bold;
      }

      .react-datepicker__input-container {
        z-index: 1000;
      }

      .question-container {
        width: 100%;
        position: relative;
        padding-top: 50px;
      }
    }
  }
}
