@import '../../styles/colors';
@import '../../styles/responsiveness';

.RoundButton {
  position: relative;
  margin: 0 auto;

  .round-button-wrapper {
    width: 30px;
    .round-button {
      transform: translate(-2%, -50%);
      position: absolute;
      width: 30px;
      z-index: 99;
    }
  }

  .round-button-wrapper:hover + .button-outline {
    width: 150%;
    border: 3px solid $orange;
  }

  .button-outline {
    z-index: 1;
    position: absolute;
    width: 25%;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    border: 0px solid $orange;
    transform: translate(-50%, -50%);
    transition: all 0.25s cubic-bezier(0.7, 0.11, 0.32, 2);
  }

  .button-outline:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

@media (max-width: $small-desktop) {
  .button-container {
    .button-outline {
      display: none;
    }
    .round-button-wrapper:hover + .button-outline {
      display: none;
    }
    button-outline:after {
      display: none;
    }
  }
}
