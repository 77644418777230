@import '../../styles/colors';
@import '../../styles/responsiveness';

.Select {
  .react-select__indicator {
    padding-right: 0px;

    &:hover {
      svg {
        fill: $steel-grey !important;
      }
    }

    svg {
      fill: $matterhorn-grey;
      width: 25px !important;
      height: 25px !important;

      &:hover {
        fill: $steel-grey;
      }
    }
  }
}
