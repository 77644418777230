@import '../../styles/colors';
@import '../../styles/responsiveness';

.FAQ {
  padding: 10% 0 10% 10%;
  position: relative;

  .FAQ-headline {
    margin-bottom: 2%;

    h1 {
      font-size: $headline;
    }
  }

  .items {
    column-count: 2;
  }

  .ContactInfo {
    top: 50%;
    bottom: 50%;
    right: 0;
    position: absolute;
  }

  .entry {
    break-inside: avoid;

    &:not(:last-of-type) {
      margin-bottom: 2.5%;
    }

    .categoryHeading {
      display: flex;

      h1 {
        color: #ffcc66;
        margin-left: 1%;
      }

      img {
        width: 2em;
      }
    }
  }

  .accordionContent {
    width: 50%;
    margin: 0 0 0 2.3%;
    font-size: 1.2em;
    font-weight: 600;
    max-width: 850px;
  }
  .accordionContent div a {
    color: $orange;
    text-decoration: none;

    &:hover {
      color: $hover;
      transition: $medium-transition;
    }
  }
  .ui.accordion {
    margin: 0 0 0 4%;
  }

  .ui.accordion .title {
    font-size: 2em;
    width: fit-content;
  }

  .ui.accordion .title:not(.ui) {
    font-size: 1.4em !important;
  }
}

@media (max-width: $medium-desktop) {
  .FAQ {
    .items {
      column-count: 1;
    }

    .accordionContent {
      width: 80%;
    }
  }
}

@media (max-width: $small-desktop) {
  .FAQ {
    .accordionContent {
      width: 70%;
    }
  }
}

@media (max-width: $medium-tablet) {
  .FAQ {
    .items {
      padding-right: 10px;
    }

    .accordionContent {
      width: 65%;
    }
  }
}

@media (max-width: $large-phone) {
  .FAQ {
    .ui.accordion {
      margin-left: 4px !important;
    }

    .ui.accordion .title:not(.ui) {
      font-size: 1.1em !important;
    }
  }
}

@media (max-width: $medium-large-phone) {
  .FAQ {
    .accordionContent {
      width: 75%;
    }
  }
}
