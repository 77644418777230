@import '../../../styles/colors';
@import '../../../styles/responsiveness';

.GuesstimatorOffer {
  background-color: $stone-grey;

  .GuesstimatorOffer-content {
    margin: 0 auto;
    display: block;
    position: relative;
    width: 80%;
    max-width: 1100px;
  }

  .top-container {
    margin-bottom: 60px;
    padding-bottom: 20px;
    padding-top: 40px;
    padding-top: 20px;
    box-shadow: 3px 3px 12px 0px rgba(50, 50, 50, 0.63);
    background-color: lighten($color: $colorDarkGrey, $amount: 3);

    padding-left: 3%;
    padding-right: 3%;
  }

  .bottom {
    width: 700px;
    display: block;
    margin: 0 auto;
  }

  .guesstimate-container {
    display: block;
    text-align: center;

    .guesstimate-text {
      display: inline-block;
      font-size: 32px;
      line-height: 38.6px;
      font-weight: bolder;
      margin-right: 0.5em;
      vertical-align: initial;
      margin-bottom: 6px;
    }

    .guesstimate {
      display: inline-block;

      span {
        color: $orange;
        text-align: center;
        font-size: 56px;
        line-height: 67.2px;
        font-weight: bolder;
      }

      .separator {
        padding-left: 0.5em;
        padding-right: 0.5em;
      }

      .currency {
        margin-left: 0.5em;
      }
    }
  }

  .legal-text {
    margin: 0.5em 0 2.5em 0;
    max-width: 700px;

    p {
      font-size: 13px;
      line-height: 16px;
      color: lighten($matterhorn-grey, 10%);
    }
  }

  a {
    text-decoration: none;
    color: $white;

    &:hover {
      color: $blue;
      transition: $fast-transition ease-in-out;
    }
  }

  p {
    color: $white;
    font-size: 1.5em;
    line-height: 1.3em;

    span,
    i {
      word-spacing: 0.1em;
    }
  }

  .sync-image-wrapper {
    position: absolute;
    right: 10px;
    top: 30%;
    width: fit-content;
    height: fit-content;
  }

  .information {
    table {
      text-align: left;
      font-size: 1.5em;
      line-height: 1.3em;
      border-collapse: separate;

      margin-left: 0px;
      padding-left: 0px;
      border-left: 0px;

      tr {
        margin-left: 0px;
        padding-left: 0px;
        border-left: 0px;
      }

      tr:not(:last-of-type) {
        td,
        th {
          padding-bottom: 15px;
        }
      }

      th {
        color: $white;
        font-weight: bolder;
        padding-right: 8em;
        vertical-align: middle;
        text-align: left;
        margin-left: 0px;
        padding-left: 0px;
        border-left: 0px;
      }

      td {
        color: $orange;
        vertical-align: middle;
      }
    }

    span,
    i {
      color: $orange;
    }
  }

  .header {
    h1 {
      text-align: center;
      font-size: 3.3em;
      color: $white;
      font-weight: bolder;
    }
    margin: 0 0 2em 0;
  }

  .sub-header-text {
    margin: 0 0 2.5em 0;
  }

  .proceed-text {
    margin: 2.5em 0 0 0;
  }

  .button-container {
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 1.5em;

    .round-button {
      height: 35px;
    }

    p {
      margin: 0 1em 0 0;
    }

    .next-button-wrapper {
      margin: 0;
      cursor: pointer;

      #arrow-path {
        fill: $orange;
      }
    }
  }

  @media (max-width: 1800px) {
    .GuesstimatorOffer-content {
      max-width: 90%;
      max-width: 1200px;
    }

    .information {
      tr,
      td,
      th {
        font-size: 20px;
        line-height: 22px;
      }

      table {
        th {
          padding-right: 30px;
        }
      }
    }

    .header {
      h1 {
        font-size: 30px;
      }
    }

    .button-container {
      p {
        font-size: 20px;
      }
    }

    .proceed-text {
      p {
        font-size: 20px;
      }
    }

    .GuesstimatorOffer-content {
      .bottom {
        margin: 0 auto;
        max-width: 90%;
        max-width: 600px;
      }

      .top-container {
        .guesstimate-text {
          font-size: 25px;
          line-height: 28px;
          vertical-align: bottom;
          margin-bottom: 1px;
        }

        .guesstimate {
          span {
            font-size: 28px;
            line-height: 31px;
          }
        }
      }
    }

    p {
      font-size: 20px;
    }
  }

  @media (max-width: 1400px) {
    margin-top: -5%;

    .top-container {
      margin-bottom: 30px;
    }
  }

  @media (max-width: $medium-large-phone) {
    min-height: 100%;
    width: 100%;
    background-color: $stone-grey;
    position: relative;

    .information {
      table tr:not(:last-of-type) td,
      table tr:not(:last-of-type) th {
        padding-bottom: 7px;
      }

      tr,
      td,
      th {
        font-size: 15px;
        line-height: 18px;
      }

      table {
        th {
          padding-right: 30px;
        }
      }
    }

    .GuesstimatorOffer-content {
      margin: 0 auto;
      display: block;
      position: relative;
      width: 100%;

      .top-container {
        padding-bottom: 25px;

        .header {
          display: none;
        }

        .guesstimate-text {
          font-size: 15px;
          display: block;
        }

        .guesstimate {
          span {
            font-size: 18px;
          }
        }
      }

      .bottom {
        width: auto;

        .proceed-text,
        .button-container,
        .legal-text {
          max-width: 90vw;
          padding-right: 40px;
          word-wrap: break-word;
        }

        .legal-text {
          margin-top: 20px;
        }
      }
    }

    p {
      color: $white;
      font-size: 1.5em;
      span {
        word-spacing: 0.1em;
      }
    }

    .information {
      span {
        color: $orange;
      }
    }

    .sync-image-wrapper {
      display: none;
    }

    .sub-header-text {
      width: 80%;
      margin: 0 0 2.5em 0;
    }

    .proceed-text {
      margin: 2.5em 0 0 0;

      p {
        font-size: 15px;
      }
    }

    .button-container {
      // height: 50px;
      display: flex;
      align-items: center;

      p {
        font-size: 15px;
        margin: 0 1em 0 0;
      }

      .next-button-wrapper {
        margin: 0;
      }
    }
  }
}
