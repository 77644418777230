@import './responsiveness';

@mixin typo-paragraph {
  font-family: 'GillSansMTPro-Light';
  font-size: 30px;
  line-height: 34px;

  @include media-breakpoint-down(xl) {
    font-size: 25px;
    line-height: 28px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 23px;
  }
}

@mixin typo-headline {
  font-family: 'GillSansMTPro-Light';
  font-size: 55px;
  line-height: 60px;

  @include media-breakpoint-down(xl) {
    font-size: 45px;
    line-height: 50px;
  }

  @include media-breakpoint-down(md) {
    font-size: 35px;
    line-height: 40px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 30px;
    line-height: 35px;
  }
}

@mixin typo-highlight {
  font-family: 'GillSansMTPro-Light';
  font-size: 30px;
  line-height: 34px;

  @include media-breakpoint-down(md) {
    font-size: 25px;
    line-height: 28px;
  }
}

@mixin typo-bold {
  font-family: 'GillSansNova-SemiBold';
  font-weight: bold;
}
