@import '../../../styles/colors';
@import '../../../styles/responsiveness';

.SynchronizerThankYou {
  max-height: 100vh;
  background-color: $stone-grey;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .content {
    margin: 0% 10% 0% 10%;
    width: 40%;

    h1 {
      font-size: 4em;
      color: $white;
    }

    p {
      font-size: 1.3em;
      color: $white;
    }

    a {
      color: $orange;

      transition: $fast-transition;
      &:hover {
        color: darken($color: $orange, $amount: 20%);
        transition: $medium-transition;
      }
    }

    span {
      color: $orange;
    }

    .button-container {
      align-self: flex-start;
      margin: 10% 0% 0% 0%;
    }
  }
}

@media (max-width: $medium-tablet) {
  .SynchronizerThankYou {
    .content {
      width: 70%;
    }
  }
}
