@import './../../styles/colors';
@import './../../styles/responsiveness';
.Pricing {
  // DESKTOP STYLING
  .pricing-section {
    position: relative;
    background-color: $stone-grey;
    min-height: 100vh;

    .spacing-fix-break, .spacing-fix-break-first {
      display: none;
    }

    .asterisk {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;

      .asterisk--content {
        display: flex;
        justify-content: left;

        .asterisk--column {
          width: 33%;
        }

        .middle-column {
          width: calc(30% + 5%);
        }

        .asterisk--column:first-of-type {
          width: calc(30% + 2.5%);
        }

        p {
          margin: 0 auto;
          color: $dove-grey;
          padding-top: 1%;
          width: 200px;
          text-align: center;
        }
      }
    }

    .ContactInfo {
      position: absolute;
      bottom: 50%;
      top: 50%;
      right: 0;
    }

    .pricing {
      padding: 5% 0 0 0;
      display: flex;
      justify-content: center;
      background-color: $stone-grey;

      flex-wrap: wrap;

      .break {
        flex-basis: 100%;
        height: 0;
      }

      .notice-elements {
        display: flex;
        width: 70%;

        margin-top: 20px;
        margin-bottom: 20px;

        .notice-element {
          width: 25%;

          &.divider-element {
            margin-right: 1.5vw;
            padding-right: 1.5vw;
          }

          .vat-notice {
            text-align: center;
            color: rgba(255, 255, 255, 0.5);

            max-width: 80%;

            margin: 0 auto;

            margin-top: 60px;
            margin-left: 20px;
          }

          .price-notice {
            text-align: center;
            color: rgba(255, 255, 255, 0.5);

            max-width: 80%;

            margin: 0 auto;
          }
        }
      }

      .pricing-elements {
        display: flex;
        width: 70%;

        .divider {
          border-left: 2px solid white;
          height: 400px;
          margin: 0 2.5% 0 2.5%;
        }

        .element {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 500px;

          &.divider-element {
            margin-right: 1.5vw;

            .element-content {
              border-right: 2px solid white;
              height: 100%;

              padding-right: 1.5vw;
            }
          }

          .element-content {
            height: 100%;
            width: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .price-title {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-weight: bold;
            font-size: 1.6em;

            img {
              width: 50px;
              height: 50px;
            }
            h2 {
              font-size: 25px;
              padding: 5% 0 5% 0;
              color: $white;
              text-align: center;

              font-weight: bold;
            }
          }
          .price-desc {
            flex: 1;

            margin-bottom: 2em;

            p {
              font-size: 1.3em;
              color: $white;
              text-align: center;
            }
          }
          .price-list {
            flex: 1;
            p {
              font-size: 1.3em;
              color: $white;
              text-align: center;
            }
          }
          .price-btn {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-top: 20px;

            .pricing-button {
              width: 150px;
            }
          }
        }
      }
    }

    .pricing-header {
      h1 {
        color: white;
        padding: 5% 0 0 10%;
        font-size: $headline;
      }
    }

    @media (max-width: 1580px) {
      .spacing-fix-break, .spacing-fix-break-first {
        display: block;
      }

      @media (max-width: 1470px) {
        .spacing-fix-break-first {
          display: none;
        }
      }
      
      @media (max-width: 1340px) {
        .spacing-fix-break-first {
          display: block;
        }

        .spacing-fix-break-second {
          content: "";
          margin: 2em;
          display: block;
          font-size: 0.8em;
        }
      }

      @media (max-width: 1340px) and (min-width: 1250px) {
        .spacing-fix-break-third {
          content: "";
          margin: 2em;
          display: block;
          font-size: 0.8em;
        }
      }
      
      @media (max-width: 1100px) {
        .spacing-fix-break-first {
          display: none;
        }
      }

      .pricing {
        .pricing-elements {
          .element {
            .price-title {
              h2 {
                font-size: 20px;
              }
            }

            .price-desc p,
            .price-list p {
              font-size: 17px;
            }
          }
        }
      }
    }
  }

  // TABLET STYLING
  .pricing-section {
    position: relative;
    background-color: $stone-grey;
    min-height: 100vh;

    .tablet-pricing {
      padding: 0% 0% 5% 0%;
      display: flex;
      justify-content: flex-start;
      background-color: $stone-grey;

      .tablet-pricing-elements {
        display: flex;
        flex-direction: column;
        width: 70%;
        // padding: 5% 0% 0% 10%;

        .tablet-element {
          display: flex;
          padding: 5% 0% 10% 10%;
          flex-direction: column;
          justify-content: center;
          width: 100vw;
          // padding-bottom: 10%;

          .asterisk {
            width: 100%;
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 10px;
            padding: 0% 0% 0% 9.5%;

            p {
              color: $dove-grey;
              padding-top: 1%;
              text-align: left;
            }
          }

          .tablet-price-title {
            flex: 1;
            display: flex;
            flex-direction: row;
            width: 100%;
            img {
              width: 50px;
            }
            h2 {
              font-size: 1.5em;
              padding: 5% 0% 5% 4%;
              color: $white;
            }
          }
          .tablet-price-desc {
            flex: 1;
            p {
              font-size: 1.3em;
              color: $white;
              text-align: start;
              font-weight: bold;
              padding: 0% 0% 5% 15%;
            }
          }
          .tablet-price-btn {
            flex: 1;
            display: flex;
            align-items: flex-end;
            padding: 0% 0% 5% 15%;
            .tablet-pricing-button {
              width: 140px;
            }
          }
        }
      }
    }

    .tablet-pricing-header {
      h1 {
        color: white;
        padding: 5% 0 0 10%;
        font-size: $headline;
      }
    }
  }

  // MOBILE AND TABLET

  @media (max-width: 972px) {
    .pricing-section {
      .pricing {
        .pricing-elements {
          .element {
            .price-title {
              h2 {
                width: max-content;
              }
            }

            .price-desc {
              align-self: auto;
              flex: 1 1 auto;
            }

            .price-list {
              margin-top: 5%;
            }
          }
        }
      }
    }
  }

  @media (max-width: $medium-desktop) {
    // Ipad
    .pricing-section {
      min-height: 65vh;

      .tablet-pricing .tablet-pricing-elements .tablet-element {
        padding: 0% 0% 0% 10%;

        .tablet-price-desc {
          width: 700px;
          p {
            padding: 0% 0% 5% 13%;
          }
        }
      }
    }

    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-btn {
      padding: 0% 0% 0% 9.5%;
    }
  }

  @media (max-width: $medium-tablet) {
    // Ipad
    .pricing-section .tablet-pricing .tablet-pricing-elements .tablet-element {
      padding: 5% 0% 0% 8%;
      .tablet-price-desc {
        width: 700px;
        p {
          padding: 0% 0% 5% 12%;
        }
      }
    }

    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-btn {
      padding: 0% 0% 0% 11%;
    }
  }

  @media (max-width: $large-phone) {
    .pricing-section {
      .asterisk {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 10px;
        padding-left: 17% !important;

        p {
          color: $dove-grey;
          padding-top: 1%;
          text-align: left;
        }
      }
    }

    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-desc {
      width: 400px;
    }

    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-btn {
      padding: 0% 0% 5% 12%;
    }
  }

  @media (max-width: 375px) {
    // IPHONE X
    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-desc {
      width: 300px;
      p {
        padding-left: 32%;
      }
    }

    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-btn {
      padding: 0% 0% 5% 18%;
    }
  }

  @media (max-width: $medium-phone) {
    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-desc {
      width: 200px;
      p {
        padding-left: 32%;
      }
    }

    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-btn {
      padding: 0% 0% 5% 18%;
    }
  }

  @media (max-width: 414px) {
    // IPHONE 6/7/8/Plus
    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-desc {
      width: 300px;
      p {
        padding-left: 22%;
      }
    }

    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-btn {
      padding: 0% 0% 5% 17%;
    }
  }

  @media (max-width: $small-phone) {
    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-desc {
      width: 300px;
    }

    .pricing-section
      .tablet-pricing
      .tablet-pricing-elements
      .tablet-element
      .tablet-price-btn {
      padding: 0% 0% 5% 9%;
    }
  }
}
