@import '../../../styles/colors';
@import '../../../styles/responsiveness';

.WhatWeKnow {
  min-height: 100vh;
  width: 100vw;
  background-color: $stone-grey;
  position: relative;

  p {
    color: $white;
    font-size: 1.5em;
    span,
    i {
      word-spacing: 0.1em;
    }
  }

  .sync-image-wrapper {
    position: absolute;
    right: 170px;
    top: 10%;
    width: fit-content;
    height: fit-content;
  }

  /*.information {
    display: grid;
    grid-template-columns: 150px 150px;

    span,
    i {
      color: $orange;
    }

    .right,
    .left {
      p {
        margin: 0 0 0.6em;
        line-height: 1.55em;
      }
    }

    .right {
      width: 500px;
    }
  }*/

  .header {
    h1 {
      font-size: 3em;
      color: $white;
      font-weight: bolder;
    }
    margin: 0 0 2em 0;
  }

  .sub-header-text {
    margin: 0 0 2.5em 0;
  }

  .proceed-text {
    margin: 2.5em 0 0 0;
    margin-bottom: 2em;
    max-width: 800px;
  }

  .button-container {
    height: 50px;
    display: flex;
    align-items: center;

    p {
      margin: 0 0.6em 0 0;
    }

    .next-button-wrapper {
      margin: 0;
    }
  }

  /* New INFORMATION block */
  .information {
    table {
      text-align: left;
      font-size: 1.5em;
      line-height: 1.3em;
      border-collapse: separate;

      margin-left: 0px;
      padding-left: 0px;
      border-left: 0px;

      tr {
        margin-left: 0px;
        padding-left: 0px;
        border-left: 0px;
      }

      tr:not(:last-of-type) {
        td,
        th {
          padding-bottom: 15px;
        }
      }

      th {
        color: $white;
        font-weight: bolder;
        padding-right: 8em;
        vertical-align: middle;
        text-align: left;
        margin-left: 0px;
        padding-left: 0px;
        border-left: 0px;
      }

      td {
        color: $orange;
        vertical-align: middle;
      }
    }

    span,
    i {
      color: $orange;
    }
  }

  @media (max-width: 1800px) {
    .GuesstimatorOffer-content {
      max-width: 90%;
      max-width: 1200px;
    }

    .information {
      tr,
      td,
      th {
        font-size: 20px;
        line-height: 22px;
      }

      table {
        th {
          padding-right: 30px;
        }
      }
    }

    .header {
      h1 {
        font-size: 30px;
      }
    }

    .button-container {
      p {
        font-size: 20px;
      }
    }

    .proceed-text {
      p {
        font-size: 20px;
      }
    }

    .GuesstimatorOffer-content {
      .bottom {
        margin: 0 auto;
        max-width: 90%;
        max-width: 600px;
      }

      .top-container {
        .guesstimate-text {
          font-size: 25px;
          line-height: 28px;
          vertical-align: bottom;
          margin-bottom: 1px;
        }

        .guesstimate {
          span {
            font-size: 28px;
            line-height: 31px;
          }
        }
      }
    }

    p {
      font-size: 20px;
    }
  }
}

@media (max-width: $medium-large-phone) {
  .WhatWeKnow {
    min-height: 100vh;
    width: 100vw;
    background-color: $stone-grey;
    position: relative;

    p {
      color: $white;
      font-size: 1.5em;
      span {
        word-spacing: 0.1em;
      }
    }

    .information {
      table tr:not(:last-of-type) td,
      table tr:not(:last-of-type) th {
        padding-bottom: 7px;
      }

      tr,
      td,
      th {
        font-size: 15px;
        line-height: 18px;
      }

      table {
        th {
          padding-right: 30px;
        }
      }
    }

    .header {
      h1 {
        font-size: 3em;
        color: $white;
        font-weight: bolder;
      }
      margin: 0 0 2em 0;
    }

    .sub-header-text {
      width: 80%;
      margin: 0 0 2.5em 0;
    }

    .proceed-text {
      margin: 2.5em 0 0 0;

      max-width: 80%;
    }

    .button-container {
      height: 50px;
      display: flex;
      align-items: center;

      margin-bottom: 70px;

      p {
        margin: 0 0.6em 0 0;
      }

      .next-button-wrapper {
        margin: 0;
      }
    }
  }
}
