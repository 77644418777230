@import './../../styles/colors';
@import './../../styles/responsiveness';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.FrontPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  background-image: linear-gradient(
      to bottom,
      rgba(35, 37, 45, 0.8),
      rgba(35, 37, 45, 0.8)
    ),
    url('/syncin-background.jpg');
  background-position: center;
  background-size: cover;

  .ContactInfo {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 0;
  }

  .content {
    display: flex;
    width: 100vw;
    align-items: center;

    .cta-button {
      visibility: hidden;
    }

    .video {
      position: relative;
      cursor: pointer;
      margin-left: 50%;
      width: 300px;
      height: 300px;

      .syncin-video {
        position: absolute;
        width: 300px;
        left: 0%;

        &:hover + .playbutton {
          width: 80px;
        }
      }

      .playbutton {
        width: 60px;
        overflow: visible;
        position: absolute;
        top: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
        transition: width $fastest-transition;

        &:hover {
          width: 80px;
          transition: all 2s;
        }
      }
    }

    .text {
      line-height: 200%;
      padding-left: 10%;
      width: 50%;

      .content-block {
        text-align: left;

        h1 {
          color: $white;
          font-size: 3em;
          font-weight: 900;

          .loop-color {
            color: $orange;
          }
        }
        h2 {
          color: $white;
          font-size: 1.5em;
          font-weight: 200;
        }

        .get-guesstimate {
          display: flex;
          margin-top: 1.5em;
          flex-direction: row;
          align-items: center;

          .get-guesstimate-button {
            margin-left: 2%;

            #arrow-path {
              fill: $orange;
            }

            .round-button {
              width: 30px;
            }
          }
        }
      }
    }
  }

  .down-button {
    $nextPageButtonSize: 50px;
    position: absolute;
    left: calc(50% - (25px));
    bottom: 30px;

    .arrow-container {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: block;

      &:hover {
        .down-arrow {
          &:before,
          &:after {
            content: '';
            background: $orange;
          }
        }
      }

      @keyframes bouncing {
        50% {
          transform: translateY(-25%);
        }

        100% {
          transform: translateY(0);
        }
      }

      animation: bouncing 1.5s ease-in-out infinite;

      .down-arrow {
        background: transparent;
        height: 6px;
        width: 50px;
        margin: 0 auto;
        top: 17px;
        position: relative;
        cursor: pointer;
        transform: rotate(90deg);
        transform-origin: center center;

        &:before,
        &:after {
          content: '';
          background: $steel-grey;
          position: absolute;
          height: 6px;
          width: 30px;
        }

        &:before {
          right: -6px;
          bottom: -8px;
          transform: rotate(-45deg);
        }

        &:after {
          right: -6px;
          top: -9px;
          transform: rotate(45deg);
        }
      }
    }
  }
}

// RESPONSIVE:
@media (max-width: $medium-desktop) {
  .FrontPage {
    min-height: 100vh;
    display: block;
    background-image: linear-gradient(
        to bottom,
        rgba(35, 37, 45, 0.8),
        rgba(35, 37, 45, 0.8)
      ),
      url('/syncin-background.jpg');
    background-position: center;
    background-size: cover;

    .content {
      display: block;
      width: 100vw;

      .cta-button {
        visibility: hidden;
      }

      .video {
        position: relative;
        cursor: pointer;
        margin-top: 5%;
        margin-left: 10%;
        width: 300px;
        height: 300px;

        .syncin-video {
          position: absolute;
          width: 300px;
          left: 0%;

          &:hover + .playbutton {
            width: 80px;
          }
        }

        .playbutton {
          width: 60px;
          overflow: visible;
          position: absolute;
          top: 50%;
          bottom: 50%;
          transform: translate(-50%, -50%);
          transition: width $fastest-transition;

          &:hover {
            width: 80px;
            transition: all 2s;
          }
        }
      }

      .text {
        line-height: 200%;
        padding-left: 10%;
        padding-top: 15%;
        width: 75%;

        .content-block {
          text-align: left;

          h1 {
            color: $white;
            font-size: 3em;
            font-weight: 900;

            .loop-color {
              color: $orange;
            }
          }
          h2 {
            color: $white;
            font-size: 1.5em;
            font-weight: 200;
          }

          .get-guesstimate {
            display: flex;
            margin-top: 1.5em;
            flex-direction: row;
            align-items: center;

            .get-guesstimate-button {
              margin-left: 2%;
              .round-button {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
}

// TABLET
@media (max-width: $medium-tablet) {
  .FrontPage {
    padding-bottom: 10%;
  }
}

@media (max-width: 823px) {
  .FrontPage {
    padding-bottom: 10%;
  }
}

@media (max-width: 823px) {
  .FrontPage {
    .content .text .content-block .get-guesstimate {
      flex-direction: column;

      .get-guesstimate-button {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 611px) {
  .FrontPage {
    .content .text .content-block .get-guesstimate {
      flex-direction: row;
    }
  }
}

// MOBILE
@media (max-width: $large-phone) {
  .FrontPage {
    .down-button {
      display: none;
    }

    .content {
      .text {
        .content-block {
          h1 {
            padding-top: 25px;
            color: $white;
            font-size: 2em;
            font-weight: 900;
          }
        }
      }
    }
  }
}
