@import './../../styles/colors';

.ContactInfo {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  flex-grow: 1;

  a {
    color: $orange;
    transform: rotate(-90deg);
    text-decoration: none;
    transition: $fast-transition;
    &:hover {
      color: darken($color: $orange, $amount: 20%);
      transition: $medium-transition;
    }
  }
}
