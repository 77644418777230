@import '../../styles/responsiveness';

.Header {
  transition: top 0.1s;
  width: 100vw;
  height: fit-content;

  .navbar {
    width: 100vw;
    z-index: 2;
    position: fixed;
    padding-left: 10%;
    height: 100px;
    top: 0;
    padding-top: 40px;
    transition: top 0.1s;
    background-color: rgb(35, 37, 45);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .navbar--hidden {
    top: -110px;
  }

  svg {
    width: 7%;
  }

  .logo {
    #Logo {
      fill: white;
    }
  }

  .bm-burger-button {
    position: fixed;
    width: 24px;
    height: 24px;
    right: 4%;
    top: 40px;
    transition: top 0.1s;

    button {
      height: 20px;
    }
  }

  .burger-bar--hidden {
    .bm-burger-button {
      top: -50px;
    }
  }
}

// RESPONSIVE
@media (max-width: $medium-desktop) {
  .Header {
    svg {
      width: 10%;
    }
  }
}

// MOBILE
@media (max-width: $medium-tablet) {
  .Header {
    svg {
      width: 12%;
    }
  }
}

@media (max-width: $large-phone) {
  .Header {
    svg {
      width: 15%;
    }

    .bm-burger-button {
      width: 24px;
      height: 24px;
      right: 6%;
      top: 40px;
      transition: top 0.1s;

      button {
        height: 20px;
      }
    }
  }
}

@media (max-width: $medium-phone) {
  .Header {
    svg {
      width: 25%;
    }
  }
}
