@import './../../styles/colors';
@import './../../styles/responsiveness';

.About {
  min-height: 100vh;
  position: relative;

  .ContactInfo {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 0;
  }

  .about-content {
    display: flex;
    padding: 10% 10% 5% 10%;

    .about-image {
      img {
        object-fit: cover;
        object-position: 50% 0;
        height: 600px;
        width: 400px;
      }
    }
    .about-text {
      max-width: 1000px;
      margin: 0 0 0 5%;
      font-size: 20px;
      font-weight: 400;

      .about-inner-content {
        column-count: 1;
      }

      h1 {
        font-size: 40px;
      }

      a {
        color: $orange;
        font-weight: 700;
        text-decoration: none;
        transition: $medium-transition;

        &:hover {
          color: $hover;
          transition: $medium-transition;
        }
      }
    }
  }
}

@media (max-width: $medium-desktop) {
  .About {
    .about-content {
      .about-text {
        .about-inner-content {
          column-count: 1;
        }
      }
    }
  }
}

// RESPONSIVE
@media (max-width: $small-desktop) {
  .About {
    min-height: 100vh;
    position: relative;

    .ContactInfo {
      display: none;
    }

    .about-content {
      display: flex;
      flex-direction: column;
      padding: 0% 10% 10% 0%;

      .about-image {
        img {
          object-fit: cover;
          object-position: 50% 0;
          // height: 600px;
          width: 100vw;
        }
      }
      .about-text {
        padding: 10% 10% 10% 10%;
        padding-right: 0px;

        width: 100%;
        margin: 0 0 0 5%;
        font-size: 20px;
        font-weight: 400;

        .about-inner-content {
          column-count: 1;
        }

        h1 {
          font-size: 2em;
          margin-top: 25px;
        }

        a {
          color: $orange;
          font-weight: 700;
          text-decoration: none;
          transition: $medium-transition;

          &:hover {
            color: $hover;
            transition: $medium-transition;
          }
        }
      }
    }
  }
}

// MOBILE
@media (max-width: 639px) {
  .About {
  }
}
