@import '../../styles/colors';

.TextInput {
  // This fixes auto-complete styling
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    border: none;
    background-color: $stone-grey;
    border-radius: 0px;
    -webkit-box-shadow: 0 0 0 50px $stone-grey inset !important;
    box-shadow: 0 0 0 50px $stone-grey inset !important;
    -webkit-text-fill-color: $orange;
  }

  // This removes arrows and spinners from numer input fields.
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .syncin-text-input {
    background-color: $stone-grey;
    text-decoration: none;

    border: none;
    width: 100%;
    height: 30px;
    font-size: 1.25em;
    padding-left: 5px;
    outline-width: 0;
    text-decoration: none;
    color: $matterhorn-grey;
    overflow: hidden;

    &:focus {
      color: $orange;
    }
  }

  .syncin-textarea-input {
    color: $orange !important;
  }

  .input-container {
    position: relative;
    overflow: hidden;

    .dropdown-arrow {
      pointer-events: none;

      img {
        pointer-events: none;
        right: 0;
        top: -2px;
        position: absolute;
        height: 20px;
      }
    }
  }

  .divider {
    height: 2px;
    width: 100%;
    background-color: $matterhorn-grey;
  }

  .syncin-textarea-input,
  .syncin-text-input {
    color: $matterhorn-grey;
    cursor: text;
  }

  &.disabled {
    .syncin-textarea-input,
    .syncin-text-input {
      cursor: default;
    }
  }

  &.focused {
    .divider {
      background-color: $white;
    }
  }

  &.hasValue {
    .syncin-textarea-input,
    .syncin-text-input {
      color: $orange;
    }
  }

  &.error {
    .divider {
      background-color: $error-red;
    }
  }
}
