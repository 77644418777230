@import './../../styles/colors';
@import './../../styles/responsiveness';

.Navigation {
  /* Color/shape of close button cross */
  .bm-cross {
    background: $colorWhite;
  }

  &.bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: $colorLightGrey;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: darken($color: $colorLightGrey, $amount: 10);
  }

  /* General sidebar styles */
  .bm-menu {
    background: $colorDarkGrey;

    padding-top: 166.5px;

    @include media-breakpoint-down(md) {
      padding-top: 100px;
    }
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: $colorDarkGrey;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;

    padding-right: 69px;
    padding-top: 17.5px;
    padding-bottom: 18.5px;
    line-height: 28px;

    text-align: right;

    font-size: 24px;
    font-weight: 300;

    text-decoration: none;

    color: $colorWhite;
    text-decoration: none;

    &.active,
    &:hover {
      color: $colorMediumGrey;
      text-decoration: none;

      outline: 0;
    }

    @include media-breakpoint-down(md) {
      font-size: 20px;
      line-height: 23px;
      padding-right: 30px;
    }
  }

  .bm-item-list {
    display: flex;
    flex-direction: column;
  }

  .bm-menu {
    padding-top: 0;
    background-image: url('/syncin-name-rotated.svg');
    background-repeat: no-repeat;

    .menu-item-container {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      .ContactInfo {
        position: absolute;
        font-size: 1rem;
        right: 0;
        flex: 1;
      }

      a {
        display: block;
        line-height: 1.2;
      }
    }

    .menu-item {
      text-align: left;
      font-weight: bold;
      margin: 1% 0% 1% 20%;
      font-size: 40px;
      color: white;
      width: fit-content;
    }

    .menu-item:hover {
      color: white;
      text-decoration: underline;
      text-decoration-color: $orange;
    }

    .menuFooter {
      margin-left: 20%;
      text-align: start;
      padding-bottom: 2.5%;
    }

    .menu-footer-item {
      color: white;
      font-weight: bold;
      margin-right: 15%;
    }

    .menu-footer-item:hover {
      color: white;
      text-decoration: underline;
      text-decoration-color: $orange;
    }
  }
}

@media (max-width: $large-phone) {
  .Navigation {
    .bm-menu {
      padding-top: 0;
      background-image: url('/syncin-name-rotated.svg');
      background-repeat: no-repeat;

      .menu-item-container {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        .ContactInfo {
          position: absolute;
          font-size: 1rem;
          left: 17%;
          right: 50%;
          transform-origin: center;
          transform: rotate(90deg);
          bottom: 50px;
          flex: 1;
        }

        a {
          display: block;
          line-height: 1.2;
        }
      }

      .menu-item {
        text-align: left;
        font-weight: bold;
        margin: 1% 0% 1% 20%;
        font-size: 40px;
        color: white;
        width: fit-content;
      }

      .menu-item:hover {
        color: white;
        text-decoration: underline;
        text-decoration-color: $orange;
      }

      .menuFooter {
        display: flex !important;
        flex-direction: column;
        margin-left: 20%;
        padding-top: -20%;
        margin-bottom: 20%;
      }
      .menu-footer-item {
        color: white;
        font-weight: bold;
        margin-right: 15%;
        margin-top: 5%;
      }

      .menu-footer-item:hover {
        color: white;
        text-decoration: underline;
        text-decoration-color: $orange;
      }
    }
  }
}
