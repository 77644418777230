/**
 * @license
 * MyFonts Webfont Build ID 3806615, 2019-09-11T15:55:14-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: GillSansMTPro-Bold by Monotype
 * URL: https://www.myfonts.com/fonts/mti/gill-sans/pro-bold/
 * 
 * Webfont: GillSansMTPro-Light by Monotype
 * URL: https://www.myfonts.com/fonts/mti/gill-sans/pro-light/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3806615
 * Licensed pageviews: 500,000
 * Webfonts copyright: Font software Copyright 2001 Adobe Systems Incorporated. Typeface designs Copyright The Monotype Corporation. All Rights Reserved.
 * 
 * © 2019 MyFonts Inc
*/

@font-face {
  font-family: 'GillSansMTPro-Bold';
  src: url('assets/fonts/3A1597_0_0.eot');
  src: url('assets/fonts/3A1597_0_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3A1597_0_0.woff2') format('woff2'),
    url('assets/fonts/3A1597_0_0.woff') format('woff'),
    url('assets/fonts/3A1597_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'GillSansMTPro-Light';
  src: url('assets/fonts/3A1597_1_0.eot');
  src: url('assets/fonts/3A1597_1_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3A1597_1_0.woff2') format('woff2'),
    url('assets/fonts/3A1597_1_0.woff') format('woff'),
    url('assets/fonts/3A1597_1_0.ttf') format('truetype');
}

.gs-bold {
  font-weight: bold;
  font-family: 'GillSansMTPro-Bold';
}

.gs-light {
  font-weight: normal;
  font-family: 'GillSansMTPro-Light';
}

/**
 * @license
 * MyFonts Webfont Build ID 3820193, 2019-10-06T13:31:24-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: GillSansNova-LightItalic by Monotype
 * URL: https://www.myfonts.com/fonts/mti/gill-sans-nova/light-italic/
 * 
 * Webfont: GillSansNova-Light by Monotype
 * URL: https://www.myfonts.com/fonts/mti/gill-sans-nova/light/
 * 
 * Webfont: GillSansNova-SemiBold by Monotype
 * URL: https://www.myfonts.com/fonts/mti/gill-sans-nova/semibold/
 * 
 * Webfont: GillSansNova-SemiBoldItalic by Monotype
 * URL: https://www.myfonts.com/fonts/mti/gill-sans-nova/semibold-italic/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3820193
 * Licensed pageviews: 500,000
 * Webfonts copyright: Copyright 2015 &#x00A9; The Monotype Corporation. All rights reserved.
 * 
 * © 2019 MyFonts Inc
*/

@font-face {
  font-family: 'GillSansNova-LightItalic';
  src: url('assets/fonts/3A4AA1_0_0.eot');
  src: url('assets/fonts/3A4AA1_0_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3A4AA1_0_0.woff2') format('woff2'),
    url('assets/fonts/3A4AA1_0_0.woff') format('woff'),
    url('assets/fonts/3A4AA1_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'GillSansNova-Light';
  src: url('assets/fonts/3A4AA1_1_0.eot');
  src: url('assets/fonts/3A4AA1_1_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3A4AA1_1_0.woff2') format('woff2'),
    url('assets/fonts/3A4AA1_1_0.woff') format('woff'),
    url('assets/fonts/3A4AA1_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'GillSansNova-SemiBold';
  src: url('assets/fonts/3A4AA1_2_0.eot');
  src: url('assets/fonts/3A4AA1_2_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3A4AA1_2_0.woff2') format('woff2'),
    url('assets/fonts/3A4AA1_2_0.woff') format('woff'),
    url('assets/fonts/3A4AA1_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'GillSansNova-SemiBoldItalic';
  src: url('assets/fonts/3A4AA1_3_0.eot');
  src: url('assets/fonts/3A4AA1_3_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3A4AA1_3_0.woff2') format('woff2'),
    url('assets/fonts/3A4AA1_3_0.woff') format('woff'),
    url('assets/fonts/3A4AA1_3_0.ttf') format('truetype');
}

@import 'node_modules/bootstrap/scss/bootstrap-reboot';
@import 'node_modules/bootstrap/scss/bootstrap-grid';

body,
html {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  font-family: 'GillSansMTPro-Light';

  ::-webkit-scrollbar {
    display: none;
  }
}
