@import '../../styles/colors';
@import '../../styles/responsiveness';

.Phone {
  .PhoneInput {
    padding-left: 5px;

    .PhoneInputInput {
      background-color: $stone-grey;
      text-decoration: none;

      border: none;
      width: 100%;
      height: 30px;
      font-size: 1.25em;
      padding-left: 5px;
      outline-width: 0;
      text-decoration: none;
      color: $matterhorn-grey;
      overflow: hidden;

      &:focus {
        color: $orange;
      }
    }
  }

  .divider {
    height: 2px;
    width: 100%;
    background-color: $matterhorn-grey;
  }

  &.error {
    .divider {
      background-color: $error-red;
    }
  }
}
