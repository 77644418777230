@import './../../styles/colors';
@import './../../styles/responsiveness';

.Testimonials {
  min-height: 100vh;
  background: $stone-grey;
  position: relative;

  .ContactInfo {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 0;
  }

  .header {
    h1 {
      padding: 5% 0% 0% 10%;
      color: $white;
      font-size: $headline;
    }
  }

  .content {
    display: flex;
    margin: 0% 10% 0% 10%;

    .visuals {
      width: 30%;

      p {
        z-index: 1;
        position: relative;
        font-size: 6em;
        transform: translate(0%, 1%);
        font-weight: 700;
        color: $white;
      }
      div {
        z-index: 0;
        img {
          max-height: 450px;
          max-width: 350px;
          transform: translateY(-20%);
        }
      }
    }

    .text {
      margin: 5% 0% 0% 5%;

      p {
        max-width: 500px;
        color: $white;
        font-size: 1.3em;
      }

      img {
        width: 8%;
        padding-bottom: 2%;
      }

      .testimonials-name {
        padding-top: 5%;
        color: $orange;
      }
    }
  }

  .progressline {
    margin: 0% 10% 0% 10%;
    padding-bottom: 4%;
  }
}

@media (max-width: $medium-desktop) {
  .Testimonials {
    .content {
      .text {
        padding-left: 15%;
      }
    }
  }
}

@media (max-width: $small-desktop) {
  .Testimonials {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ContactInfo {
      display: none;
    }

    .content {
      flex-direction: column;
      margin: 0% 10% 0% 10%;
      padding-left: 5%;

      .text {
        padding-left: 0%;
      }

      .visuals {
        margin: 0% 0% 0% 5%;

        p {
          transform: translate(0%, 1%) translateY(60%);
          font-weight: 700;
          color: $white;
        }
        div {
          z-index: 0;
          img {
            max-height: 450px;
            max-width: 350px;
            transform: translateY(0%);
          }
        }
      }
    }

    .header {
      h1 {
        padding: 15% 0% 0% 15%;
        color: $white;
        font-size: $headline;
      }
    }

    .progressline {
      width: 100%;

      .progress-line {
        transform-origin: left;
        transform: rotate(90deg) translateX(22.5%);
      }
    }
  }
}

@media (max-width: $medium-tablet) {
}

@media (max-width: $large-phone) {
  .Testimonials {
    min-height: 120vh;

    .progressline {
      .progress-line {
        transform: rotate(90deg) translateX(25%);
      }
    }

    .content {
      .visuals {
        div {
          z-index: 0;
          img {
            max-height: 350px;
            max-width: 250px;
            transform: translateY(0%);
          }
        }
      }
    }
  }
}

@media (max-width: $medium-phone) {
  .Testimonials {
    .header {
      padding: 0% 10% 0% 10%;
    }

    .content {
      margin-top: -50px;
      .visuals {
        div {
          z-index: 0;
          img {
            max-height: 300px;
            max-width: 200px;
            transform: translateY(0%);
          }
        }
      }
    }
  }
}
