@import '../../styles/colors';
@import '../../styles/responsiveness';

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
    transform-origin: center center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center center;
  }
}

#__lpform_input_idx_0 {
  display: none;
}

.Guesstimator {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10% 10% 5% 10%;
  background-color: $stone-grey;
  min-height: 100vh;

  .error-message {
    color: $error-red;
    margin-top: 10px;
    font-size: 16px;
  }

  .tooltip {
    background-color: $matterhorn-grey !important;
    max-width: 400px !important;
    line-height: 18px;
  }

  .tooltip-button {
    border-radius: 50%;
    border: 1px solid $matterhorn-grey;
    padding-top: 3px;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    display: inline-block;
    line-height: 20px;
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      border-color: $orange;
      background-color: $orange;
    }
  }

  .headline {
    h1 {
      b {
        font-size: 40px;
      }
    }
  }

  .CheckBox {
    transform: translateX(-10px);
  }

  .loaderWrapper {
    position: absolute;
    right: 50%;
    left: 50%;
    bottom: 50%;
    top: 50%;
    transform: translateY(-50px) translateX(-50px);

    width: 100%;
    @media (prefers-reduced-motion: no-preference) {
      img {
        animation: App-logo-spin infinite 2.5s linear;
      }
    }
    img {
      transform-origin: center center;
      width: 100px;
      height: 110px;
      pointer-events: none;
    }
  }

  .ContactInfo {
    position: absolute;
    right: 0;
    bottom: 50%;
    top: 50%;
  }

  .guesstimator-content {
    width: 100%;
  }

  h1 {
    font-size: 3em;
    color: $white;
    font-weight: bolder;
  }

  .meta-description {
    padding-top: 3%;
    width: 45%;
    font-size: 1.5em;
    color: $white;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .ProgressLine {
    margin: 10% 0 10% 0;
  }

  .GuesstimatorPage {
    .description {
      padding-top: 3%;
      width: 45%;
      font-size: 1.5em;
      color: $white;
      font-weight: bold;
    }

    .question-container {
      width: 40%;
      position: relative;
      padding-top: 50px;

      .question-wrapper {
        margin-bottom: 50px;
      }

      .terms-and-conditions {
        display: flex;
        flex-direction: row;

        p {
          margin-left: 10px;
          font-size: 16px;
          color: $white;
          margin-top: 0.3em; // "temporary" hard fix to place text center relative to checkbox
        }

        a {
          text-decoration: none;
          color: $orange;

          &:hover {
            color: $hover;
            transition: $fast-transition ease-in-out;
          }
        }
      }

      .button-container {
        padding-top: 10%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: $medium-tablet) {
  .Guesstimator {
    .ProgressLine {
      padding-top: 15%;
    }
    .GuesstimatorPage {
      .meta-description {
        width: 80%;
      }

      .description {
        width: 80%;
      }
      .question-container {
        width: 80%;
      }
    }
  }
}

@media (max-width: $medium-desktop) {
  .Guesstimator {
    .ProgressLine {
      padding-top: 20%;
    }
    .GuesstimatorPage {
      .meta-description {
        width: 90%;
      }

      .description {
        width: 90%;
      }
      .question-container {
        width: 90%;
      }
    }
  }
}

@media (max-width: $medium-large-phone) {
  .Guesstimator {
    padding: 5% 5% 5% 5%;

    .headline {
      h1 {
        b {
          font-size: 30px;
        }
      }
    }
  }
}
