@import '../../styles/typography';
@import '../../styles/colors';

.Terms {
  padding: 0 10% 0 10%;
  position: relative;
  background-color: $stone-grey;

  min-height: 100vh;

  .text-content {
    width: 100%;
    height: 100%;
    padding-top: 10%;
    padding-bottom: 10%;

    h1 {
      font-family: 'GillSansNova-SemiBold';
      font-style: normal;
      font-weight: bold;
      font-size: $headline;
      //line-height: 56px;
      /* identical to box height */

      color: $white;

      margin-bottom: 38px;
    }

    h2 {
      font-family: 'GillSansNova-SemiBold';
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 37px;

      color: $white;
    }

    hr {
      width: 60%;
      max-width: 850px;
      margin-left: 0px;
      margin-top: 39px;
    }

    .text-section {
      color: $white;
      width: 60%;
      max-width: 850px;

      &:not(:first-of-type) {
        margin-top: 39px;
      }

      p {
        font-family: 'GillSansMTPro-Light';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
      }

      p:first-of-type {
        margin-top: 33px;
      }

      p.spacing {
        margin-top: 31px;
      }
    }
  }

  @media (max-width: $medium-tablet) {
    padding-top: 100px;

    .text-content {
      hr {
        width: 80%;
      }

      .text-section {
        width: 80%;
      }
    }
  }

  @media (max-width: $large-phone) {
    .text-content {
      hr {
        width: 100%;
      }

      .text-section {
        width: 100%;
      }
    }
  }
}
