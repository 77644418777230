@import '../../styles/colors';
@import '../../styles/responsiveness';

@mixin btn-border-drawing(
  $color: #ccc,
  $hover: $hover,
  $width: 3px,
  $vertical: top,
  $horizontal: left,
  $duration: 0.25s
) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative; // highlights hover on the sides

  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute; // has impact on width on surrounding boxes
    width: 0;
    height: 0;

    #{$vertical}: 0; // impacts hover effect
    #{$horizontal}: 0; // impacts hover effect
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');

    border-#{$vertical}-width: $width; // highligts lower line
    border-#{$h-side}-width: $width; // highligts lower line
  }

  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');

    border-#{$v-side}-width: $width; // highligts upper line
    border-#{$horizontal}-width: $width; // highligts upper line
  }

  &:hover {
    color: $hover;

    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }

    &::before {
      transition-delay: 0s, 0s, $duration;
    }

    &::after {
      transition-delay: 0s, $duration, 0s;
    }
  }
}

.drawer-border {
  @include btn-border-drawing($white, $orange, 3px, bottom, right);
}

//=== Button styling, semi-ignore
.Button {
  display: inline-block; // do not remove
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font: 1.2rem 'Roboto Slab', sans-serif;
  padding: 0.7em 1.5em;
  letter-spacing: 0.05rem;
  white-space: nowrap; // forces text to be on one line
  text-align: center;

  // From this point on is legacy styling code:
  &.white-transparent {
    color: $colorWhite;
    border: 1px solid $colorOtherGrey;

    &.active,                       // used when toggling a button
    &:hover {
      // used when hovering a button
      color: $white;
      background: $black;
    }
  }

  &.disabled {
    color: $matterhorn-grey;
    cursor: auto;
    border-color: $matterhorn-grey;
    box-shadow: inset 0 0 0 3px $matterhorn-grey;

    &:hover {
      &::before,
      &::after {
        border-color: $matterhorn-grey;
        width: 100%;
        height: 100%;
      }
    }
  }

  &.black {
    // black theme
    color: $white;
    background: $stone-grey;
  }

  &:focus {
    outline: 2px dotted $white;
  }
}

@media (max-width: $small-desktop) {
  .drawer-border {
    @include btn-border-drawing($white, $white, 3px, bottom, right);
  }
}
