@import '../styles/typography';
@import '../styles/colors';
@import '../styles/responsiveness';

b {
  @include typo-bold;
}

.App {
  background-color: white;
  overflow: hidden;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-l-40 {
  margin-left: 40px;
}
